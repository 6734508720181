import React, { useState } from 'react'
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { SectionPage, Container, BreadCrumb, SectionPageTitle , MarkdownContent, SectionDescription } from "../components/Section"
import  PlusIcon  from "../components/Icons/PlusIcon"

const DropWrapp = styled.div`
	cursor: pointer;
	padding: 15px 30px;
	border: 1px solid #98DFFF;
	border-radius: 5px;
	box-shadow: ${props=>(props.isVisible ? "0 15px 45px rgba(152, 223, 255, 0.2)" : "none")};
	margin-bottom:15px;
	
`
const DropButton = styled.div`
	font-size: 24px;
	text-align: left;
	color: #484D4E;
	margin-top: 0;
	width: 100%;
	display: flex;
	line-height: 30px;
	align-items: center;
	@media(max-width: 480px) {
		font-size: 20px;
	}
`
const DropButtonText = styled.div`
	width: calc(100% - 30px);
	display: inline-block;
	color: #062C44;
	font-family: Chakra Petch;
	font-weight: 700; 
	line-height: 29px;
`
const DropButtonImg = styled.div`
	width: 30px;
	display: inline-block;
	& svg {
		float: right;
		transform: ${props=>(props.isVisible ? "rotate(45deg)" : "rotate(90deg)")};
		transition: all .3s ease;
	}
`
const FadeContent = styled.div`
	display: ${props=>(props.isVisible ? "block" : "none")};
	border-top: 1px solid #98DFFF;
	margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 30px;
	@media(max-width: 480px) {
		margin-top: 15px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
`

const FaqsPage = (pageData) =>{
	const [currentFaq, setCurrentFaq] = useState();
	const handleOpen = (i) => {
		if(currentFaq === i) {
			setCurrentFaq();
		} else {
			setCurrentFaq(i);
		}
	}

	return(
		<Layout location={pageData.location}>
			<SEO title="Faqs" description="Faqs" />
			<SectionPage
				ept="160px"
				epb="60px"
				xpt="140px"
				xpb="40px"
				pt="100px"
				pb="30px"
				bg="#fff"
			>
				<Container>
					<BreadCrumb className='static'><Link to="/">Home</Link> / <span>FAQ's </span></BreadCrumb>
					<SectionPageTitle textAlign="center">Frequently Asked Questions</SectionPageTitle>
					<SectionDescription><p>Here are several frequently asked questions customers have asked during the buying process.  If you have additional questions please feel free to speak to one of our building specialists.</p></SectionDescription>
					{
						pageData.data.allContentfulFaq.edges.map((item, i) => {
							return (
								<DropWrapp isVisible={currentFaq === i ? true : false}>
									<DropButton onClick={() => handleOpen(i)} isVisible={currentFaq === i ? true : false} >
										<DropButtonText>
											{item.node.question}
										</DropButtonText>
										<DropButtonImg isVisible={currentFaq === i ? true : false}>
											<PlusIcon fill="#1E4156" />
										</DropButtonImg>
									</DropButton>
									<FadeContent isVisible={currentFaq === i ? true : false}>
										<MarkdownContent dangerouslySetInnerHTML={{ __html: item.node.answer.childMarkdownRemark.html }} />
									</FadeContent>
								</DropWrapp>
							)
						})
					}
				</Container>
			</SectionPage>
			<CallToAction />
		</Layout>
	)
}

export default FaqsPage

export const pageQuery = graphql`
  query FaqsPageQuery {
	  allContentfulFaq {
	    edges {
	      node {
	        question
	        answer {
	          childMarkdownRemark {
	            html
	          }
	        }
	      }
	    }
	  }
	}
`